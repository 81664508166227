import { makeStyles } from '@mui/styles';
import { Typography, Divider, Link } from '@mui/material';
import { Grid } from 'components/controls';

import Carousel from './carousel';

const Home = () => {
  return (
    <Grid container direction="column" sx={{ m: 3 }}>
      <Grid item>
        <Carousel />;
      </Grid>
    </Grid>
  );
};
export default Home;
