import { Typography } from '@mui/material';
import { en } from 'config/metadata/en';

const CopyRights = () => {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright'} &copy; {en.copyRights} {new Date().getFullYear()}.
    </Typography>
  );
};
export default CopyRights;
