import MuiBox from '@mui/material/Box';

const Box = ({ children, ...rest }) => {
  return <MuiBox {...rest}>{children}</MuiBox>;
};
export default Box;

/*******************************
 * Example for import
    <Box>
        <Label
            name="username"
            label={en.login.username}
          />
    </Box>
 */
