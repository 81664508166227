import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import { useHistory } from 'react-router-dom';

import useStyles from './style';
import { UserSelector, UserAction } from 'state/user';
import { DropDown } from 'components/controls';
import useControlEvent from 'utils/controlevent';

const ClientBar = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const clients = useSelector(UserSelector.getUserClients);
  const currentClient = useSelector(UserSelector.getUserCurrentClient);
  const currentBusType = useSelector(UserSelector.getUserCurrentBusType);
  //const clientName = useSelector(UserSelector.getUserCurrentClientName);
  const _clientID = useSelector(UserSelector.getUserCurrentClientID);
  const { controlVal, setControlVal, onFieldValueChange } = useControlEvent({
    client: _clientID,
  });

  const updateCurrentBusSubType = (clientID, busType, busSubType = null) => {
    const busSubTypes = clients[clientID]?.busTypes[busType];

    let _busSubType = busSubType;
    if (!busSubType && busSubTypes && busSubTypes.length > 0) {
      _busSubType = busSubTypes[0];
    }
    const _currentBusSubType = {
      clientID: clientID,
      busType: busType,
      busSubType: _busSubType,
    };

    dispatch(UserAction.UserCurrentBusSubType(_currentBusSubType));
    if (!busSubType) {
      const newControlVal = controlVal;
      newControlVal.busSubType = _currentBusSubType.busSubType;
      setControlVal(newControlVal);
    }
  };

  const updateCurrentBusType = (clientID, busType = null) => {
    const busTypes = clients[clientID].busTypes;
    const _currentBusType = {
      clientID: clientID,
      busType: null,
      busSubTypes: [],
    };
    if (!busType && Object.keys(busTypes).length > 0) {
      const _busTypes = Object.entries(busTypes);
      _currentBusType.busType = _busTypes[0][0];
      _currentBusType.busSubTypes = _busTypes[0][1];
    } else {
      _currentBusType.busType = busType;
      _currentBusType.busSubTypes = busTypes[busType];
    }
    dispatch(UserAction.UserCurrentBusType(_currentBusType));

    if (!busType) {
      const newControlVal = controlVal;
      newControlVal.busType = _currentBusType.busType;
      setControlVal(newControlVal);
    }
    updateCurrentBusSubType(clientID, _currentBusType.busType);
  };

  useEffect(() => {
    const clientID = controlVal.client;
    const busType = controlVal.busType;
    const busSubType = controlVal.busSubType;
    updateCurrentBusSubType(clientID, busType, busSubType);
    history.push('/');
  }, [controlVal.busSubType]);

  useEffect(() => {
    const clientID = controlVal.client;
    const busType = controlVal.busType;
    updateCurrentBusType(clientID, busType);
    history.push('/');
  }, [controlVal.busType]);

  useEffect(() => {
    let clientID = controlVal.client;
    if (!clientID && Object.keys(clients).length > 0) {
      clientID = Object.values(clients)[0].clientID;
      setControlVal({ client: clientID });
    }
    dispatch(UserAction.UserCurrentClient(clients[clientID]));
    updateCurrentBusType(clientID);
    history.push('/');
  }, [controlVal.client]);

  return (
    <>
      <DropDown
        name="client"
        //label= 'Client'
        value={controlVal.client}
        onChange={onFieldValueChange}
        options={
          clients &&
          Object.values(clients).map((x) => ({
            label: x.clientName,
            value: x.clientID,
          }))
        }
        className={classes.clientList}
        valueCellClassName={classes.clientValueCell}
      />
      <DropDown
        name="busType"
        //label= 'Business Type'
        value={controlVal.busType}
        onChange={onFieldValueChange}
        options={
          currentClient &&
          Object.keys(currentClient.busTypes).map((x) => ({
            label: x,
            value: x,
          }))
        }
        className={classes.clientList}
        valueCellClassName={classes.clientValueCell}
      />
      <DropDown
        name="busSubType"
        //label= 'Business Type'
        value={controlVal.busSubType}
        onChange={onFieldValueChange}
        options={
          currentBusType &&
          currentBusType.busSubTypes.map((x) => ({
            label: x,
            value: x,
          }))
        }
        className={classes.clientList}
        valueCellClassName={classes.clientValueCell}
      />
    </>
  );
};

export default ClientBar;

/*************
 *  <Typography className={classes.clientBar} variant="h6" noWrap>
          {clientName}
        </Typography>
  */
