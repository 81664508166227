import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useHistory } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useDispatch } from 'react-redux';

import { en } from 'config/metadata/en';
import useControlEvent from 'utils/controlevent';
import DiaLogBox from 'components/dialogbox';
import { TextBox, Button } from 'components/controls';
import CopyRights from 'components/copyrights';
import { forgetpassw } from './service';
import { AppAction } from 'state/app';
import { APP_VERSION } from 'config/app.config';
import { IconList } from 'icons';
import Notification from 'components/notification';
import { UserAction } from 'state/user';

import useStyles from './style';

const ForgetPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    controlVal,
    controlErrorVal,
    setControlErrorVal,
    onFieldValueChange,
  } = useControlEvent({ username: '' });

  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [loading, setLoading] = useState(false);

  const resetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const user = await forgetpassw({
        username: controlVal.username,
      });
      setIsSuccess(true);
      setOpenNotification(true);

      dispatch(
        AppAction.AppAll({
          version: APP_VERSION,
        })
      );
      dispatch(UserAction.UserAll({}));
      const timeout = setTimeout(() => {
        history.push(
          `/confirmpassw?qusername=${controlVal.username}&qtype=FORGOT_PASSWORD`
        );
      }, 3000);
    } catch (e) {
      console.log('Forgot password request failed');
      console.log(e);
      setIsSuccess(false);
      setOpenNotification(true);
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>{IconList.User}</Avatar>
        <Typography component="h1" variant="h5">
          {en.forgotpassw.title}
        </Typography>
        <form
          className={classes.form}
          autoComplete="off"
          noValidate
          onSubmit={resetPassword}
        >
          <TextBox
            name="username"
            label={en.forgotpassw.username}
            value={controlVal.username}
            onChange={onFieldValueChange}
            error={controlErrorVal.username}
            autoFocus
            required
            fullWidth
          />

          <Button
            label={en.forgotpassw.submit}
            fullWidth
            startIcon={IconList.Email}
            className={classes.submit}
            disabled={loading}
          />
          <Notification
            type={isSuccess ? 'success' : 'error'}
            message={isSuccess ? 'Code sent successfully' : 'Invalid username'}
            openNotification={openNotification}
            setOpenNotification={setOpenNotification}
          />
        </form>
      </div>
      <Box mt={6}>
        <CopyRights />
      </Box>
    </Container>
  );
};
ForgetPassword.propTypes = {};
export default ForgetPassword;
