import { useState, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { Grid, Button } from 'components/controls';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';

import useStyles from './style';
import { cartContext } from 'shared/context/cart';
import CheckoutItem from './checkoutitem';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const CheckOut = () => {
  const classes = useStyles();
  const { cartItems, setCartItems } = useContext(cartContext);

  return (
    <Box className={classes.root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {cartItems.length > 0 && (
            <Stack direction="row" spacing={2}>
              <Button
                //  type="submit"
                variant="contained"
                color="primary"
                size="large"
                label="Proceed To Order"
                component={Link}
                to={'/confirmorder'}
              />
              <Typography variant="h6" component="div" noWrap>
                Total Amount:
                <Box component="span" fontWeight="bold">
                  ₹
                  {cartItems.reduce(
                    (prev, current) => prev + current.totalPrice,
                    0
                  )}
                </Box>
              </Typography>
            </Stack>
          )}
        </Grid>
        <Grid item xs={12}>
          <Divider
            style={{
              border: '1px solid green',
              borderBottomWidth: 1,
              marginTop: 1,
              marginBottom: 10,
            }}
          />
        </Grid>

        {cartItems.length > 0 &&
          cartItems.map((pitem, index) => (
            <Grid item key={index}>
              {
                <CheckoutItem
                  id={pitem.id}
                  title={pitem.title}
                  thumbnailUrl={pitem.thumbnailUrl}
                  price={pitem.price}
                  sellingPrice={pitem.sellingPrice}
                  unit={pitem.unit}
                  quantity={pitem.quantity}
                  totalQuantity={pitem.totalQuantity}
                  totalPrice={pitem.totalPrice}
                />
              }
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};
export default CheckOut;
