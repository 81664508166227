import { useState, useContext } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { useHistory } from 'react-router-dom';

import useControlEvent from 'utils/controlevent';
import { DropDown, Button } from 'components/controls';
import { IconList } from 'icons';

import { cartContext } from 'shared/context/cart';
import Notification from 'components/notification';

const BasicInfo = ({ product }) => {
  const quantityList = [1, 2, 5, 10, 15, 20, 25, 30, 50];
  const [quantity, setQuantity] = useState(0);
  const { controlVal, setControlVal, onFieldValueChange } = useControlEvent({});
  const { cartItems, setCartItems } = useContext(cartContext);
  const [openNotification, setOpenNotification] = useState(false);
  const history = useHistory();

  const addItemToCart = () => {
    if (!controlVal.quantity) {
      setOpenNotification(true);
      return;
    }

    const newCartItems = [];
    const newProduct = { ...product };
    newProduct.totalQuantity = controlVal.quantity;
    newProduct.totalPrice =
      controlVal.quantity * (newProduct.sellingPrice / newProduct.quantity);
    for (const item of cartItems) {
      if (item.id !== newProduct.id) {
        newCartItems.push(item);
      }
    }

    newCartItems.push(newProduct);
    setCartItems(newCartItems);
    return true;
  };

  const buyProduct = () => {
    if (addItemToCart()) {
      history.push('/checkout');
    }
  };
  return (
    <Stack direction="column">
      <h3>{product.title}</h3>

      <Divider sx={{ marginBottom: '20px' }} />
      <Typography variant="h6" align="left" component="div">
        <Box
          component="span"
          sx={{ textDecoration: 'line-through', color: 'red' }}
        >
          {' '}
          ₹{product.price / product.quantity}
        </Box>
        <Box component="span" fontWeight="bold">
          {' '}
          ₹{product.sellingPrice / product.quantity}
        </Box>{' '}
        / {product.unit}
        <Box component="span" sx={{ fontSize: '10px' }}>
          {' + Delivery charges'}
        </Box>
      </Typography>
      <Typography variant="h6" align="left" component="div">
        <Box component="span" fontWeight="bold">
          <DropDown
            name="quantity"
            label="Quantity"
            //sx={{fontWeight:"bold"}}
            //width="30"
            //  minWidth="20"
            //minWidth
            value={controlVal.quantity}
            onChange={onFieldValueChange}
            options={quantityList.map((item) => ({
              label: item.toString(),
              value: item,
            }))}
          />
        </Box>
      </Typography>
      <Box>
        <Stack direction="row" spacing={{ xs: 1, sm: 2, md: 4 }}>
          <Button
            label="Add To Cart"
            startIcon={IconList.ShoppingCart}
            onClick={addItemToCart}
          />
          <Button
            label="Buy Now"
            startIcon={IconList.Buy}
            onClick={buyProduct}
          />
        </Stack>
      </Box>
      <Notification
        type="error"
        message="Please select quantity"
        openNotification={openNotification}
        setOpenNotification={setOpenNotification}
      />
    </Stack>
  );
};

export default BasicInfo;
