const validator = (data) => {
  const vdalidate = {};
  if (!data.username) {
    vdalidate['username'] = 'User Name required';
  }
  if (!data.password) {
    vdalidate['password'] = 'Password required';
  }
  return vdalidate;
};

export default validator;
