import Typography from '@mui/material/Typography';
import MuiIconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';

import { Grid, Box } from 'components/controls';
import { en } from 'config/metadata/en';
import { IconList } from 'icons';

import useStyles from './style';

const Logo = () => {
  const classes = useStyles();
  return (
    <Grid container direction="row" align="center" spacing={1}>
      <Grid item>
        <Box
          component="img"
          sx={{
            height: 80,
            width: 250,
            display: 'block',
            //maxWidth: 300,
            //   overflow: 'hidden',
            //width: '100%',
          }}
          src="logo.png"
          alt="Cholas Logo"
        />
      </Grid>
    </Grid>
  );
};

export default Logo;

/*
  <MuiIconButton
      key='Namazhvar'
     // iconStyle={styles.largeIcon}
     // size='small'
     disabled
     >
      {IconList.Namazhvar}
    </MuiIconButton>


    <MuiIconButton
      key='Uzhavu'
      disabled
     >
      {IconList.Uzhavu}
    </MuiIconButton>
*/
