import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';
import { IconList } from 'icons';

const TopMenus = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="Home"
        // onClick={console.log('')}
        color="inherit"
        component={Link}
        to="/"
      >
        Home
      </Button>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        color="inherit"
        endIcon={IconList.DownArrow}
      >
        Products
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{
          color: '#000000',
        }}
        // color = '#000000'
        //color="inherit"
        // backgroundColor="#0000ff"
      >
        <MenuItem onClick={handleClose} component={Link} to="/products?c=rice">
          Rice
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/products?c=aval">
          Aval
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/products?c=millet"
        >
          Millet
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/products?c=oil">
          Wood Pressed Oil
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          component={Link}
          to="/products?c=jaggery"
        >
          Jaggery
        </MenuItem>
        <MenuItem onClick={handleClose} component={Link} to="/products?c=seeds">
          Seeds
        </MenuItem>
      </Menu>
      <Button id="About Us" component={Link} to="/aboutus" color="inherit">
        About US
      </Button>
      <Button
        id="Contact"
        // onClick={console.log('')}
        color="inherit"
        component={Link}
        to="/contact"
      >
        Contact
      </Button>
    </>
  );
};

export default TopMenus;
