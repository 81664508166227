export const carouselItemList = [
  {
    label: 'Cholas Paaramabariya Uzhavan(Cholas Trading)',
    description:
      'Paaramabariya Uzhavan platform connects thousands of farmers and customers. This platform delivers naturally farmed traditional varieties of products to the customers with the best quality and original taste at the lowest price.',
    imgPath: '/images/uzhavu.png',
  },
  {
    label: 'Traditional Rice',
    description:
      'Platform choose highly nutrition contained traditional varieties of rice from farmers. Each selected variety has unique medical benefits',
    imgPath: '/images/paddy-rice.jpg',
  },
];
