import { orderAPI } from 'config/api-routlink';
import request from 'shared/service/request';

export const confirmOrder = async (params) => {
  const rdata = await request.post(orderAPI.CONFIRM_ORDER, {
    items: params.items,
    details: params.details,
  });

  return {
    data: rdata.data?.data,
  };
};
