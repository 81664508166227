import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useHistory } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useDispatch } from 'react-redux';

import { en } from 'config/metadata/en';
import useControlEvent from 'utils/controlevent';
import DiaLogBox from 'components/dialogbox';
import { TextBox, Button } from 'components/controls';
import CopyRights from 'components/copyrights';
import { userAuthenticate } from './service';
import { UserAction } from 'state/user';
import { AppAction } from 'state/app';
import { APP_VERSION } from 'config/app.config';
import { IconList } from 'icons';

import useStyles from './style';
import validator from './validator';

const Login = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    controlVal,
    controlErrorVal,
    setControlErrorVal,
    onFieldValueChange,
  } = useControlEvent({ username: '', password: '' });

  const [isLoginFailed, setIsLoginFailed] = useState(false);
  const [loading, setLoading] = useState(false);

  const signIn = async (e) => {
    e.preventDefault();
    setLoading(true);

    const errorVal = validator(controlVal);
    setControlErrorVal(errorVal);
    if (Object.keys(errorVal).length) {
      setLoading(false);
      return;
    }

    try {
      const { setchangepassword, user } = await userAuthenticate({
        username: controlVal.username,
        password: controlVal.password,
      });
      // const setchangepassword = false;

      if (setchangepassword) {
        history.push(
          `/confirmpassw?qusername=${controlVal.username}&qtype=FORCE_PASSWORD_CHANGE`
        );
      } else {
        dispatch(
          AppAction.AppAll({
            version: APP_VERSION,
          })
        );
        dispatch(UserAction.UserAll(user));
        history.push('/');
      }
    } catch (e) {
      console.log('Login failure');
      console.log(e);
      dispatch(UserAction.UserAll({}));
      setIsLoginFailed(true);
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>{IconList.User}</Avatar>
        <Typography component="h1" variant="h5">
          {en.login.signin}
        </Typography>
        <form
          className={classes.form}
          autoComplete="off"
          noValidate
          onSubmit={signIn}
        >
          <TextBox
            name="username"
            label={en.login.username}
            value={controlVal.username}
            onChange={onFieldValueChange}
            error={controlErrorVal.username}
            autoFocus
            required
            fullWidth
          />

          <TextBox
            name="password"
            label={en.login.password}
            value={controlVal.password}
            onChange={onFieldValueChange}
            error={controlErrorVal.password}
            autoFocus
            required
            fullWidth
            type="password"
          />

          <Button
            label={en.login.signin}
            fullWidth
            startIcon={<LockOpenIcon />}
            className={classes.submit}
            disabled={loading}
          />

          <Grid container>
            <Grid item xs>
              <Link href="/forgotpassw" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={6}>
        <CopyRights />
      </Box>
      <DiaLogBox
        openDialog={isLoginFailed}
        setOpenDialog={setIsLoginFailed}
        title={en.login.failure.dialogTitle}
        message={en.login.failure.dialogMessage}
      />
    </Container>
  );
};
Login.propTypes = {};
export default Login;
