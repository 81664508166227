import { createTheme, adaptV4Theme } from '@mui/material/styles';
const theme = createTheme(
  {
    palette: {
      primary: {
        main: '#096b4f',
      },
    },
  }
  /*
     text: {
          primary: "#ffffff"
        },
     adaptV4Theme(
    {
    palette: {
      primary: {
        main: '#2A8D4C',
        // main: 'white',
      },
      secondary: {
        main: '#16b543',
      },
    },
    overrides: {
      MuiTableCell: {
        head: {
          backgroundColor: '#f2f6f7',
          fontWeight: 'bold',
        },
      },
      MuiTooltip: {
        tooltip: {
          //fontSize: '1em',
          backgroundColor: '#f7f7df',
          color: 'black',
        },
      },
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  } )
  */
);
export default theme;
