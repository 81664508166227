import MuiTooltip from '@mui/material/Tooltip';

const Tooltip = ({ title, children }) => {
  return title ? <MuiTooltip title={title}>{children}</MuiTooltip> : children;
};
export default Tooltip;

/********************************
 * Example import
 *
    <Tooltip
        title="Add"
      >
        <Button>Controlled</Button>
      </Tooltip>
 */
