import { Typography, Box } from '@mui/material';
import { Grid } from 'components/controls';

const AboutUs = () => {
  return (
    <Grid container direction="column" sx={{ m: 3 }}>
      <Grid item>
        <Typography variant="h4" gutterBottom>
          About Us
        </Typography>
      </Grid>
      <Grid item>
        <Typography sx={{ p: 2 }}>
          <Box sx={{ fontWeight: 'bold', display: 'inline' }}>
            Cholas Trading
          </Box>{' '}
          started the business to delivery the naturally farmed agriculture
          products all over the india through e-commerce platform. Then expanded
          our products to export for various continents and countries across the
          world. Our goal is to deliver quality products and create trust on our
          brand (
          <Box sx={{ fontWeight: 'bold', display: 'inline' }}>
            Cholas Paarambaraiya Uzhavan
          </Box>
          ) to the customers
        </Typography>
      </Grid>
    </Grid>
  );
};
export default AboutUs;
