import { createContext, useState } from 'react';

import useControlEvent from 'utils/controlevent';

export const cartContext = createContext();
cartContext.displayName = 'cart';

const CartProvider = (props) => {
  // Filter
  const { controlVal, setControlVal, onFieldValueChange } = useControlEvent({});

  const [cartItems, setCartItems] = useState([]);

  return (
    <cartContext.Provider
      value={{
        controlVal,
        setControlVal,
        onFieldValueChange,
        cartItems,
        setCartItems,
      }}
    >
      {props.children}
    </cartContext.Provider>
  );
};

export default CartProvider;
