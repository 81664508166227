export const contactDetails = [
  {
    name: 'Registered & Adminstration Office',
    addressLines: [
      'No-224/226, Arulmigu Vachaniamman Koil St,',
      'Uluthur, Bhuvanagiri Tk,',
      'Cuddalore District, Tamilnadu-608 704',
    ],
    email: 'cholastrading@gmail.com',
    contactNo: '+91 999 404 1004',
    isPrimary: true,
  },
  {
    name: 'Chennai Enquiry Office',
    addressLines: [
      '#100, F3, Sri Alamelumangapuram,',
      'Sholinganallur, Chennai-600 119',
    ],
    contactNo: '+91 999 404 1004',
  },
  {
    name: 'Coimbatore Enquiry Office',
    addressLines: [
      '#41, Sri Sakthi Garden,Kovilpalayam,',
      'Sarkarsamakulam, Coimbatore-641 107',
    ],
    contactNo: '+91 999 404 1004',
  },
  {
    name: 'Bangalore Enquiry Office',
    addressLines: [
      '#3, 6th Main Road,',
      'NRI Layout, Ramamurthy Nagar,',
      'Bangalore, Karnataka-560 016',
    ],
    contactNo: '+91 81059 49289',
  },
  {
    name: 'Dubai Enquiry Office',
    addressLines: ['A3,1008 , Pearl towers,', 'Al Rashidiya 2, Ajman, Dubai'],
    contactNo: '+97 154 747 5112',
  },
  {
    name: 'Australia Enquiry Office',
    addressLines: ['836 Bridge inn road, Doreen, Victoria- 3754'],
    contactNo: '+61 433 076 662',
  },
  {
    name: 'Canada Enquiry Office',
    addressLines: ['10145,1007, 109 street NW,', 'Edmonton,  Alberta, T5J3M5'],
    contactNo: '+1 587 778 7970',
  },
];
