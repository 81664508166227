import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';

const DropDown = (props) => {
  const {
    name,
    label,
    value = '',
    onChange,
    options = [],
    className,
    valueCellClassName,
    minWidth = 120,
    margin = 15,
    error,
    //required=false,
    ...rest
  } = props;
  const converTargetEvent = (name, value) => ({
    target: { id: name, name: name, value: value },
  });
  return (
    //
    <FormControl
      id={'FormControl-' + name}
      {...(!className && { style: { minWidth: minWidth, margin: margin } })}
      {...(className && { className: className })}
    >
      <InputLabel id={'dropdownlabel-' + name}>{label}</InputLabel>
      <Select
        labelId={name}
        variant="standard"
        name={name}
        id={name}
        value={value}
        onChange={(e) =>
          onChange(converTargetEvent(e.target.name, e.target.value))
        }
        autoWidth
        displayEmpty
        inputProps={{ 'aria-label': 'Without label' }}
        // required
        //{...rest}
        className={valueCellClassName}
      >
        {options.map((mitem, mid) => (
          <MenuItem key={mid} id={mitem.value} value={mitem.value}>
            {mitem.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText sx={{ color: 'red' }}>{error}</FormHelperText>}
    </FormControl>
  );
};

export default DropDown;

/************************************************************************************************
 * Example import for DropDown
 * <DropDown
            name= 'country'
            label= 'Country' 
            value= {controlVal.country}
            onChange= {onChange}
            options= {[{label:'India', value:'india'},{label:'Singapore', value:'singapore'}]}
         />
 */
