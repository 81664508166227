import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useHistory } from 'react-router-dom';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { en } from 'config/metadata/en';
import useControlEvent from 'utils/controlevent';
import DiaLogBox from 'components/dialogbox';
import { TextBox, Button } from 'components/controls';
import CopyRights from 'components/copyrights';
import { confirmpassw } from './service';
import { AppAction } from 'state/app';
import { APP_VERSION } from 'config/app.config';
import { IconList } from 'icons';
import Notification from 'components/notification';
import { UserAction } from 'state/user';

import useStyles from './style';

const ConfirmPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { search } = useLocation();

  const {
    controlVal,
    controlErrorVal,
    setControlErrorVal,
    onFieldValueChange,
  } = useControlEvent({ veri: '' });

  const [openNotification, setOpenNotification] = useState(false);
  const [isSuccess, setIsSuccess] = useState(true);
  const [notificationMsg, setNotificationMsg] = useState(true);
  const [loading, setLoading] = useState(false);

  const confirmPassword = async (e) => {
    e.preventDefault();

    const queryParams = new URLSearchParams(search);

    if (controlVal.newpassword !== controlVal.confirmnewpassword) {
      setNotificationMsg('Password and confirm password mismatch');
      setIsSuccess(false);
      setOpenNotification(true);
      return;
    }

    setLoading(true);
    try {
      const user = await confirmpassw({
        username: props.username || queryParams.get('qusername'),
        type: queryParams.get('qtype'),
        verificationcode: controlVal.verificationcode,
        newpassword: controlVal.newpassword,
      });
      setNotificationMsg('Password reset successfully');
      setIsSuccess(true);
      setOpenNotification(true);

      dispatch(
        AppAction.AppAll({
          version: APP_VERSION,
        })
      );
      dispatch(UserAction.UserAll({}));
      const timeout = setTimeout(() => {
        history.push('/login');
      }, 3000);
    } catch (e) {
      console.log('Reset password request failed');
      console.log(e);
      setNotificationMsg('Password reset failed');
      setIsSuccess(false);
      setOpenNotification(true);
    }
    setLoading(false);
  };

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>{IconList.User}</Avatar>
        <Typography component="h1" variant="h5">
          {en.confirmpassw.title}
        </Typography>
        <form
          className={classes.form}
          autoComplete="off"
          noValidate
          onSubmit={confirmPassword}
        >
          <TextBox
            name="verificationcode"
            label={en.confirmpassw.verificationCode}
            value={controlVal.verificationcode}
            onChange={onFieldValueChange}
            error={controlErrorVal.verificationcode}
            autoFocus
            required
            fullWidth
            type="password"
          />
          <TextBox
            name="newpassword"
            label={en.confirmpassw.newpassword}
            value={controlVal.newpassword}
            onChange={onFieldValueChange}
            error={controlErrorVal.newpassword}
            autoFocus
            required
            fullWidth
            type="password"
          />
          <TextBox
            name="confirmnewpassword"
            label={en.confirmpassw.confirmnewpassword}
            value={controlVal.confirmnewpassword}
            onChange={onFieldValueChange}
            error={controlErrorVal.confirmnewpassword}
            autoFocus
            required
            fullWidth
            type="password"
          />

          <Button
            label={en.confirmpassw.submit}
            fullWidth
            // startIcon={IconList.Email}
            className={classes.submit}
            disabled={loading}
          />

          <Notification
            type={isSuccess ? 'success' : 'error'}
            message={notificationMsg}
            openNotification={openNotification}
            setOpenNotification={setOpenNotification}
          />
        </form>
      </div>
      <Box mt={6}>
        <CopyRights />
      </Box>
    </Container>
  );
};
ConfirmPassword.propTypes = {};
export default ConfirmPassword;
