import { login } from 'api/auth';
import { dateFormat } from 'utils/date';

const filterTransform = (filter) => {
  const _filter = {};
  for (const [key, value] of Object.entries(filter)) {
    if (value) {
      const cf = customFilterFieldsTrans(key, value);
      _filter[cf.key] = cf.value;
    }
  }
  return _filter;
};

const responseDataTransform = (records = []) => {
  records = records.map((item, index) => {
    item.DOS_Max = dateFormat(item.DOS_Max);
    item.CreatedAt = dateFormat(item.CreatedAt);
    item.UpdatedAt = dateFormat(item.UpdatedAt);
    item.IsHistorical = item.IsHistorical ? 'Yes' : '';
    if (!item.id) {
      item.id = index;
    }
    return item;
  });
  return records;
};

export const userAuthenticate = async ({ username, password }) => {
  const options = {
    username,
    password,
  };

  const { setchangepassword, data } = await login(options);
  if (setchangepassword) {
    return { setchangepassword, data: null };
  }
  const user = {
    detail: {
      name: data.user.name,
      email: data.user.email,
    },
    username: data.user.name,
    token: data.id_token,
    refreshToken: data.refresh_token,
    roles: data.user.roles,
  };

  if (Object.keys(data.user.clients).length > 0) {
    user.clients = data.user.clients;
    const currentClient = user.clients[Object.keys(user.clients)[0]];
    user.currentClient = {
      clientName: currentClient.clientName,
      clientID: currentClient.clientID,
      clientKey: currentClient.clientKey,
      busTypes: currentClient.busTypes,
    };
    if (Object.keys(currentClient.busTypes).length > 0) {
      const _busType = Object.keys(currentClient.busTypes)[0];
      user.currentBusType = {
        clientID: currentClient.clientID,
        busType: _busType,
        busSubTypes: currentClient.busTypes[_busType],
      };
      if (user.currentBusType.busSubTypes.length) {
        user.currentBusSubType = {
          clientID: currentClient.clientID,
          busType: user.currentBusType.busType,
          busSubType: user.currentBusType.busSubTypes[0],
        };
      }
    }
  }
  return { setchangepassword, user };
};
