import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { AppBar } from './style';
import AppBarLayout from './appbar';
//import SideNavBar from './sidenavbar';
import Footer from './footer';
import SideNavBar from './sidenavbar';
import { DrawerHeader, Drawer } from './sidenavbar/style';
import TopMenus from './topmenus';
import CartProvider from 'shared/context/cart';
const MainLayout = (props) => {
  //const classes = useStyles();
  const { children } = props;
  const [handleOpen, setHandleOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <CssBaseline />

      <AppBarLayout handle={[handleOpen, setHandleOpen]} />

      <Container component="main" sx={{ mt: 4, mb: 2 }}>
        <DrawerHeader />
        {children}
      </Container>

      <Footer />
    </Box>
  );
};
//  <Box component="main" sx={{  flexGrow: 1, p: 3 , marginBottom: '-100px', backgroundColor:'blue' }}>
// </Box>

// <AppBarLayout handle={[handleOpen, setHandleOpen]} />
// <SideNavBar open={handleOpen} setOpen={setHandleOpen} />
/*
 <SideNavBar open={handleOpen} setOpen={setHandleOpen}/>    
    <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
    <DrawerHeader />
    {children}
    </Box>
*/

// const MainLayout = (props) => {
//   const classes = useStyles();
//   const { children } = props;
//   const [handleOpen, setHandleOpen] = React.useState(false);

//   return (
//     <div className={classes.root}>
//       <CssBaseline />
//       <AppBarLayout handle={[handleOpen, setHandleOpen]} />
//       <SideNavBar handle={[handleOpen, setHandleOpen]} />
//       <main
//         className={clsx(classes.content, {
//           [classes.contentShift]: handleOpen,
//         })}
//       >
//         {children}
//         <Footer />
//       </main>
//     </div>
//   );
// };
MainLayout.prototype = {
  children: PropTypes.node.isRequired,
};
export default MainLayout;
