import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const DiaLogBox = ({
  openDialog,
  setOpenDialog,
  title,
  message,
  closeText,
  submitText,
  submitClick,
  style = {},
}) => {
  return (
    <Dialog
      open={openDialog}
      onClose={() => setOpenDialog(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ backgroundColor: style.backgroundColor }}>
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {submitText && (
            <Button onClick={submitClick} color="primary">
              {submitText}
            </Button>
          )}
          <Button onClick={() => setOpenDialog(false)} color="primary">
            {closeText ? closeText : 'Close'}
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
DiaLogBox.propTypes = {
  openDialog: PropTypes.bool,
  setOpenDialog: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
};
export default DiaLogBox;

/**
  <DiaLogBox 
      openDialog={openDialog}
      setOpenDialog={setOpenDialog}
      title={title}
      message={message}
      submitText={'Submit'}
      submitClick={submitClick}
  />

*/
