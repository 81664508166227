import { forgetpassw as _forgetpassw } from 'api/auth';

export const forgetpassw = async ({ username }) => {
  const options = {
    username,
  };

  const { data } = await _forgetpassw(options);
  return data;
};
