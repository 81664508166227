import makeStyles from '@mui/styles/makeStyles';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginTop: theme.spacing(1),
      marginbottom: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(20),
      marginRight: theme.spacing(20),
      marginTop: theme.spacing(1),
      marginbottom: theme.spacing(1),
    },
  },
}));
export default useStyles;
