import * as ActionType from './actiontypes';

// action creators
export const AppAll = (user) => {
  return { type: ActionType.APP_ALL, value: user };
};

export const AppVersion = (version) => {
  return { type: ActionType.APP_VERSION, value: version };
};
