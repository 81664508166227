import { Route, Switch } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';

import PrivateRoute from './privateroute';
import { routePages } from 'config/page-menu.config';
import HomeLayout from 'layout/home';

import HomeRoute from './homeroute';
import GoogleAnalytics from '../google-analytics';

// <Route component={NotFound} />
const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        {routePages.map((page, index) => {
          switch (page.isPrivateRoute) {
            case true:
              return (
                <PrivateRoute
                  path={page.path}
                  roles={page.roles}
                  component={page.component}
                  {...(page.exact && { exact: page.exact })}
                />
              );

            default:
              return (
                <HomeRoute
                  path={page.path}
                  roles={page.roles}
                  component={page.component}
                  {...(page.exact && { exact: page.exact })}
                />
              );
          }
        })}
      </Switch>
      <GoogleAnalytics />
    </BrowserRouter>
  );
};
export default Router;
