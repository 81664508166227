import { useState } from 'react';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { menuList } from 'config/page-menu.config';
import { UserSelector } from 'state/user';

import {
  DrawerHeader,
  Drawer,
  StyleLogoutlist,
  StyleSelectedMenu,
} from './style';

import { Tooltip } from 'components/controls';
import { IconList } from 'icons';
import useStyles from './style';

const drawerWidth = 240;

const SideNavBar = ({ open, setOpen }) => {
  const theme = useTheme();
  const classes = useStyles();
  //const [open, setOpen] = React.useState(false);

  const userRoles = useSelector(UserSelector.getUserRoles);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const onMenuClick = (menuName) => {
    setSelectedMenu(menuName);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerClose}>
          {theme.direction === 'rtl' ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        {menuList
          .filter((x) => x.Roles.some((y) => userRoles.includes(y)))
          .map((item, index) => (
            <ListItem
              onClick={() => onMenuClick(item.Name)}
              className={clsx({
                [classes.selectedMenu]: selectedMenu === item.Name,
              })}
              button
              key={item.Name}
              component={Link}
              to={item.Path}
            >
              <Tooltip key={'tooltip-' + item.Name} title={item.Name}>
                <ListItemIcon>
                  {IconList[item.Icon]
                    ? IconList[item.Icon]
                    : IconList['Default']}
                </ListItemIcon>
              </Tooltip>
              <ListItemText primary={item.Name} />
            </ListItem>
          ))}
      </List>
      <Divider />
      <div className={classes.logoutlist}>
        <List>
          <ListItem button key="logout" component={Link} to="/login">
            <Tooltip key="tooltip-logout" title="Logout">
              <ListItemIcon>{IconList['Exit']}</ListItemIcon>
            </Tooltip>
            <ListItemText primary="logout" />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default SideNavBar;
