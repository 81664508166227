import axios from 'axios';
//import Auth from './auth';
import { API_KEY } from 'config/api-endpoints';
import { authAPI } from 'config/api-routlink';

import { UserSelector, UserAction } from 'state/user';
import { useDispatch } from 'react-redux';
import configureStore from 'state/configurestore';

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
  },
};

const request = axios.create(defaultOptions);

const getNewTokenFromRefreshToken = async ({ username, refreshToken }) => {
  const rdata = await request.post(authAPI.REFRESH_TOKEN, {
    username,
    refresh_token: refreshToken,
  });

  const token = rdata.data.data.id_token;
  const newRefreshToken = rdata.data.data.refresh_token;
  const store = configureStore();
  const { dispatch, useSelector } = store;
  dispatch(UserAction.UserToken(token));
  dispatch(UserAction.UserRefreshToken(newRefreshToken));
  return token;
};

// API Request
request.interceptors.request.use(async (config) => {
  config.headers['X-Api-Key'] = API_KEY;
  const serviceUrl = new URL(config.url);
  if (serviceUrl.pathname?.includes('/auth/')) {
    return config;
  }

  // No addition Header for s3 url operations
  if (
    serviceUrl.hostname?.includes('amazonaws.com') &&
    serviceUrl.hostname?.includes('.s3.')
  ) {
    return config;
  }

  const store = configureStore();
  const { dispatch, useSelector } = store;
  const state = store.getState();
  if (!state.user?.token) {
    dispatch(UserAction.UserLogout());
  }
  config.headers['Authorization'] = `Bearer ${state.user?.token}`;
  config.headers['ClientID'] = `${state.user?.currentBusSubType?.clientID}`;
  config.headers['BusType'] = `${state.user?.currentBusSubType?.busType}`;
  config.headers['BusSubType'] = `${state.user?.currentBusSubType?.busSubType}`;
  return config;
});

// API Response
request.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    try {
      const store = configureStore();
      const { dispatch, useSelector } = store;
      const serviceUrl = new URL(error.config.url);
      console.log(`API error status=${error.response?.status}`);
      console.log(`URL =${serviceUrl.pathname}`);

      // If refresh token fails, logout | clear session to force login again
      if (
        !error.response ||
        (serviceUrl.pathname === '/auth/token/refreshtoken' &&
          error.response.status !== 200)
      ) {
        const { dispatch, useSelector } = store;
        dispatch(UserAction.UserLogout());
      }

      // If Token expired , get new token from refresh token and request again
      if (
        !serviceUrl.pathname?.startsWith('/auth/') &&
        error.response.status === 401 &&
        error.config &&
        !error.config._retry
      ) {
        const state = store.getState();
        error.config._retry = true;
        const tokenParams = {
          username: state.user.username,
          refreshToken: state.user.refreshToken,
        };
        getNewTokenFromRefreshToken(tokenParams).then((token) => {
          err.config.headers.Authorization = `Bearer ${token}`;
          return request(error.config);
        });
      } else {
        return Promise.reject(error);
      }
    } catch (e) {
      return Promise.reject(error);
    }
  }
);
export default request;
